import { createStore } from 'vuex'
import { actions } from "./actions";
import { mutations } from "./mutations";

let store = createStore({
  strict: true,
  state: {
    selected:{
      item:null,
      tabbarVisible: true,
    },
    data:{
      username: null,
      backendVersion: null,
      usergroups:[],
      groupmembers:[],
      userconfig:{},
      photosToPreview:null,
      photosToUpload:null,
      searchResultItems:[],
      searchResultTags:[],
      searchResultFilter: [],
      searchResultFilterStorage: [],
      searchResultFilterGeolocation: null,
      searchResultFilterRadius: null,
      scannedItems:[],
      basket: initBasket(),
      lastItemAdded: null,
    },
    ui:{
      config: (localStorage.config && JSON.parse(localStorage.config)) || {
        rememberLastStorageInAdd:false,
        cloudprinter:null,
      },
      categories:["all","cables","costume","sound","stage","props"],
      field2categoryMap:{
          length: {showIn:["cables","sound"],hideIn:["props","costume"]},
          colors: {showIn:["costume","cables"],hideIn:["stage"]},
          sizes: {showIn:["costume"],hideIn:["*"]}
      }
    }
  },
  mutations,
  actions,
  modules: {
  }
})

function initBasket(){
  try{
    return JSON.parse(localStorage.basket);
  }
  catch(e){
    return []
  }
}

export default store
