<template>
<div>
  <div class="loginfullscreen is-flex is-flex-direction-column is-justify-content-center" v-if="showlogin && !signupActive">
    <h1 class="m-3">Login</h1>

    <div class="is-flex is-flex-direction-column is-align-content-center m-2 is-90-pc-width">

        <input class="input mt-2" type="text" placeholder="username" ref="username">
        <input class="input mt-2" type="password" placeholder="password" ref="password" @keypress.enter="handleSubmit">

        <button class="button mt-3 has-background-dark has-text-light" @click="handleSubmit">LOGIN</button>

        <div class="mt-3">
          <a @click="signupActive = true">Sign up</a>
        </div>

    </div>
  </div>
  <Signup v-else-if="showlogin"/>
</div>
  
</template>

<script>
import { useStore } from "vuex";
import {ref } from "@vue/runtime-core";

import Signup from '@/components/ItemForm/Signup'

export default {
  name: 'Login',
  components:{Signup},
  setup(){

      const store = useStore();

      const username = ref(null);
      const password = ref(null);

      const showlogin = ref(localStorage.authtoken == "null");
      const signupActive = ref(false);

      document.addEventListener("toggleAuth",()=>{
        showlogin.value = true;
      })

      const handleSubmit = async ()=>{
          store.dispatch("login",{
              username:username.value.value,
              password:password.value.value
            });
      }
      return {
          handleSubmit,
          username,
          password,
          showlogin,
          signupActive
     }
  }
}
</script>

<style lang="scss" scoped>


</style>
