import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
//import AddDing from '../views/AddDing.vue'
const AddDing = () => import('../views/AddDing.vue')
//import EditDing from '../views/EditDing.vue'
const EditDing = () => import('../views/EditDing.vue')
//const SearchItems = () => import('../views/SearchItems.vue')
import PseudoSearchItems from '../views/PseudoSearchItems.vue'
//import Scan from '../views/Scan.vue'
const Scan = () => import('../views/Scan.vue')
//import Basket from '../views/Basket.vue'
const Basket = () => import('../views/Basket.vue')
//import Success from '../views/Success.vue'
const Success = () => import('../views/Success.vue')
import Detail from '../views/Detail.vue'
//const Detail = () => import('../views/Detail.vue')
//import ScannedItems from '../views/ScannedItems.vue'
const ScannedItems = () => import('../views/ScannedItems.vue')
import QRCodeDisplay from '../views/QRCodeDisplay.vue'
//const QRCodeDisplay = () => import('../views/QRCodeDisplay.vue')
const Move = () => import('../views/Move.vue')
const Reserve = () => import('../views/Reserve.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Basket',
    name: 'Basket',
    component: Basket
  },
  {
    path: '/AddDing',
    name: 'AddDing',

    component: AddDing
  },
  {
    path: '/EditDing',
    name: 'EditDing',

    component: EditDing
  },
  {
    path: '/Scan',
    name: 'Scan',
    component: Scan
  },
  {
    path: '/SearchItems',
    name: 'SearchItems',
    component: PseudoSearchItems
  },
  {
    path: '/Success',
    name: 'Success',
    component: Success
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/ScannedItems',
    name: 'ScannedItems',
    component: ScannedItems
  },
  {
    path: '/QRCodeDisplay',
    name: 'QRCodeDisplay',
    component: QRCodeDisplay
  },
  {
    path: '/Move',
    name: 'Move',
    component: Move
  },
  {
    path: '/Reserve',
    name: 'Reserve',
    component: Reserve
  }
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router
